exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-controle-de-vendas-index-js": () => import("./../../../src/pages/controle-de-vendas/index.js" /* webpackChunkName: "component---src-pages-controle-de-vendas-index-js" */),
  "component---src-pages-controle-de-vendas-markdown-remark-frontmatter-slug-jsx": () => import("./../../../src/pages/controle-de-vendas/{markdownRemark.frontmatter__slug}.jsx" /* webpackChunkName: "component---src-pages-controle-de-vendas-markdown-remark-frontmatter-slug-jsx" */),
  "component---src-pages-guided-breathing-index-js": () => import("./../../../src/pages/guided-breathing/index.js" /* webpackChunkName: "component---src-pages-guided-breathing-index-js" */),
  "component---src-pages-guided-breathing-privacy-policy-js": () => import("./../../../src/pages/guided-breathing/privacy-policy.js" /* webpackChunkName: "component---src-pages-guided-breathing-privacy-policy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-notas-de-corretagem-index-js": () => import("./../../../src/pages/notas-de-corretagem/index.js" /* webpackChunkName: "component---src-pages-notas-de-corretagem-index-js" */),
  "component---src-pages-notas-de-corretagem-markdown-remark-frontmatter-slug-jsx": () => import("./../../../src/pages/notas-de-corretagem/{markdownRemark.frontmatter__slug}.jsx" /* webpackChunkName: "component---src-pages-notas-de-corretagem-markdown-remark-frontmatter-slug-jsx" */),
  "component---src-pages-renda-fii-index-js": () => import("./../../../src/pages/renda-fii/index.js" /* webpackChunkName: "component---src-pages-renda-fii-index-js" */),
  "component---src-pages-renda-fii-privacy-policy-js": () => import("./../../../src/pages/renda-fii/privacy-policy.js" /* webpackChunkName: "component---src-pages-renda-fii-privacy-policy-js" */)
}

